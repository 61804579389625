<template>
  <div>
    <div class="modidy_Auth">
      <div class="main">
        <div class="step">
          <el-steps align-center :active="active">
            <el-step  v-for="(item,index) in stepsPhoneTitle"
                     :title="item" :key="index"></el-step>
          </el-steps>
        </div>
        <el-form key="1" v-if="active == 1" :model="ruleForm" :rules="rules" ref="ruleForm" class="form" @submit="submitForm('ruleForm')"
                 >
          <el-form-item prop="phone">
            <el-input :disabled="true" v-model="phone" >
              <template slot="prepend">
                   <span style="display: flex;align-items: center" class="el-dropdown-link">
                      <img style="height: 27px" :src="regionCodeObject.srcImg" alt=""> <span style="font-size: 16px;margin-left: 5px"> +{{regionCodeObject.code}}</span> <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code" class="code">
            <el-input v-model="ruleForm.code" auto-complete="off" :placeholder="$t(`logins.form.code.placeholder`)"
                      maxlength="6" @keyup.enter.native="submitForm('ruleForm')"></el-input>
            <el-button class="code_btn" :disabled="codeDisabled" @click="getCode">{{codeMsg}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="btn" @click="goSetpassword('ruleForm')" :loading="loading>0" :disabled="disabledBtn">
            提交验证
            </el-button>
          </el-form-item>
        </el-form>

        <el-form key="2" v-if="active == 2" :model="ruleForm" :rules="rule"  ref="ruleForm4" class="form">
          <el-form-item prop="password">
            <el-input type="password" v-model="ruleForm.password" auto-complete="off" placeholder="请设置6位以上的密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="repeatpassword" >
            <el-input type="password" v-model="ruleForm.repeatpassword" auto-complete="off" placeholder="请再次输入密码"></el-input>
          </el-form-item>
          <el-form-item v-show="!wechatLogin">
            <el-button  class="btn" @click="RSetpasswordSuccess('ruleForm4')" :loading="loading>0" :disabled="disabledBtn">
              提交</el-button>
          </el-form-item>
        </el-form>

        <div key="3" class="success" v-if="active == 3">
          <p>
            <i><img src="@/assets/images/common/success_icon.png" alt=""></i>
            密码修改成功
          </p>
          <div class="btn" @click="backAccount">返回个人中心</div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
    <footers></footers>
  </div>
</template>

<script>
  import { SMS, VXCHECK, account, regist, smsVerification, retrieve } from "@/request/login";
  import {mapMutations, mapState} from 'vuex'
  import { USER } from "@/request/common";
  import login from "@/components/common/login"
  import footers from '@/components/common/xfooter'
  import tool from "../../assets/js/tool";
  export default {
    name: "modidyOrAuth",
    //   head () {
    //     return this.$setSeo(this.$t("login.h1") + " | Lanternfish", "login")
    //   },
    data () {

      // 表单验证规则
      // 校验手机号码
      // 表单验证规则
      // 校验手机号码
      var phoneReg = /^[1][0-9]{10}$/,
        codeReg = /^\d{6}$/;
      var validatePhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t('logins.form.phone.placeholder')))
        }
        setTimeout(() => {
          if (!value) {
            callback(new Error(this.$t('logins.form.phone.errorMessage')))
          } else {
            callback()
          }
        }, 1000)
      };
      // 校验验证码
      var validateCode = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t('logins.form.code.placeholder')))
        }
        setTimeout(() => {
          if (!codeReg.test(value)) {
            callback(new Error(this.$t('logins.form.code.errorMessage')))
          } else {
            callback()
          }
        }, 1000)
      };
      return {
        phone: '',
        regionCodeObject: {
          srcImg: '/images/national/zgdl.jpg',
          code: '86'
        },
        //记录用户手机号
        Reiphone:null,
        //设置验证令牌
        Retoken: null,
        stepsPhoneTitle: [
          "安全验证", "设置密码", "完成"
        ],
        loading: 0,
        routerType: 0,
        active: 1,  //1 第一步 2 第二步
        // 是否禁用按钮
        codeDisabled: false,
        // 倒计时秒数
        countdown: 60,
        // 按钮上的文字
        codeMsg: this.$t('logins.form.sendCodeText'),
        // 定时器
        timer: null,
        token: Math.random(),
        // 表单验证
        rules: {
          phone: [
            { required: true, validator: validatePhone, trigger: 'blur' }
          ],
          code: [
            {
              required: true,
              validator: validateCode,
              trigger: "blur"
            }
          ]
        },
        rule: {
          password: [
            { required: true, message: '密码长度大于6位以上', trigger: 'blur' },
            { min: 6, message: '密码长度大于6位以上', trigger: 'blur' }
          ],
          repeatpassword: [
            { required: true, message: '请设置6位以上密码', trigger: 'blur' },
            { min: 6, message: '请设置6位以上密码', trigger: 'blur' }
          ],
        },
        // 表单参数
        ruleForm: {
          phone: '',
          code: '',
          password: undefined,
          repeatpassword:''
        },
        disabledBtn: false,
        dialogVisible: false,
        dialogClose: false,
        //5秒定时器
        fiveTime: null
      }
    },
    components: {
      login,footers,
      ...mapState(['me'])
    },
    beforeDestroy() {
      clearInterval(this.fiveTime)
    },
    mounted () {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
      document.body.appendChild(s);
      this.routerType = this.$route.query.type
      if (this.$route.query.active != null) {
        this.active = this.$route.query.active;
      } else {
        setTimeout(() => {
          this.wxLogins()
        }, 500);
      }
      // 获取用户信息
      this.getUserInfo();
    },
    methods: {
      ...mapMutations(['setMe', 'setToken']),
      handleCommand(command) {
        if(command.indexOf('852') == 0){
          this.selectVal = '852'
          this.regionCodeObject.srcImg = '/images/national/zgxg.jpg'
          this.regionCodeObject.code = '852'
        }else if(command.indexOf('853') == 0){
          this.selectVal = '853'
          this.regionCodeObject.srcImg = '/images/national/am.jpg'
          this.regionCodeObject.code = '853'
        }else{
          this.selectVal = '86'
          this.regionCodeObject.srcImg = '/images/national/zgdl.jpg'
          this.regionCodeObject.code = '86'
        }
      },
      keyer(){
        let count = 0;
        clearInterval(this.fiveTime)
        this.fiveTime = setInterval(() => {
          count += 1
          if(count == 5){
            count = 0
            clearInterval(this.fiveTime)
            this.$router.push({
              path: "/personalCenter/account"
            })
          }
        },1000)
      },
      //密码修改
      RSetpasswordSuccess(formName){
        this.$refs[formName].validate(result => {
          if(result){
            if(this.ruleForm.password == this.ruleForm.repeatpassword){
              retrieve({
                password: this.ruleForm.password,
                token: this.Retoken,
                subject: 'SMS@' + this.Reiphone
              }).then(res => {
                if(res.code == 0){
                  // this.SetpasswordSuccess()
                  this.keyer()
                  this.active = 3
                }else {
                  this.$message('密码修改失败');
                }
              })
            }else {
              this.$message('密码输入不一致');
            }
          }else {
            this.$message('密码格式不正确');
          }
        })

      },
      getIphone(val){
        if(val.indexOf("86") == 0){
          var matchReg = /^86/;
          return val.replace(matchReg, "");
        }else if(val.indexOf("852") == 0){
          var matchReg = /^852/;
          return val.replace(matchReg, "");
        }else if(val.indexOf("853") == 0){
          var matchReg = /^853/;
          return val.replace(matchReg, "");
        }else {
          return val
        }
      },
      // 获取用户信息
      async getUserInfo () {
        let user = await USER();
        if (user.code == 0) {
          this.setMe(user.data);
          this.ruleForm.phone = this.$store.state.me.phone
          this.phone = this.getIphone(this.$store.state.me.phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2'))
          this.handleCommand(this.$store.state.me.phone)
        }
      },
      wxLogins () {
        var appid = "";
        var uriAll = window.location.href;
        var getindex = uriAll.indexOf(".cn");//获取指定字符串在原字符串条中的位置
        var getstr = uriAll.substring(0, getindex);
        if (getstr.indexOf("test.lanternfish") != -1) {
          appid = 'wx855ff947adfb6ef2'
        } else {
          appid = 'wx6a278bb6f3d73649'
        }
        this.tool.setCookie("loginUrl", this.$route.path);
        var obj = new WxLogin({
          self_redirect: false,
          id: 'qrcode',
          appid: appid,
          scope: 'snsapi_login',
          redirect_uri: encodeURIComponent(`http://${window.location.host}/callback`),
          state: '/',
          style: 'black',
          href: 'data:text/css;base64,QGNoYXJzZXQgIlVURi04IjsNCi5pbXBvd2VyQm94e3dpZHRoOmF1dG87fQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIyN3B4O2hlaWdodDogMjI3cHg7bWFyZ2luLXRvcDowO2JvcmRlcjpub25lO30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAud3JwX2NvZGV7d2lkdGg6MjI3cHg7fQ==',
        });
      },
      // 获取验证码
      getCode () {
        this.codeDisabled = true;
        setTimeout(() => {
          this.codeDisabled = false;
        }, 1000);
        this.$refs.ruleForm.validateField('phone', async phoneError => {
          if (!phoneError) {
            let data = await this.$axios.get(`/admin/mobile/sendCode/SMS@${this.selectVal == '86' ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone}`)
            if (data.data.code == 0) {
              if (!this.timer) {
                this.timerEvent();
              }
            } else {
              this.$message({
                message: data.data.msg
              });
            }
          }
        });
      },
      // 倒计时
      timerEvent () {
        this.codeDisabled = true;
        this.timer = setInterval(() => {
          if (this.countdown > 0 && this.countdown <= 60) {
            this.countdown--;
            if (this.countdown !== 0) {
              this.codeMsg = this.countdown + " s";
            } else {
              clearInterval(this.timer);
              this.codeMsg = this.$t('logins.form.resendText');
              this.countdown = 60;
              this.timer = null;
              this.codeDisabled = false;
            }
          }
        }, 1000)
        // this.smsAjax();
      },
      // 验证码接口
      async smsAjax () {
        const codeData = await this.$axios.get('/admin/mobile/sendCode/SMS@' + this.ruleForm.phone
        )
        if (codeData.data.code == 0) {
          // this.$message({
          //   type: "success",
          //   message: codeData.data.msg
          // });
        } else {
          this.$message({
            message: codeData.data.msg
          });
          clearInterval(this.timer);
          this.codeMsg = this.$t('logins.form.resendText');
          this.countdown = 60;
          this.timer = null;
          this.codeDisabled = false;
        }
      },
      // 表单提交
      //是否可以去设置密码
      goSetpassword(formName){
        // this.ruleForm = {
        //   phone: '',
        //   code: '',
        //   password: undefined
        // },
        // this.userName = false
        // this.register = false
        // this.quickLogin = false
        // this.forgetPassword = false
        // this.setPassword = false
        // this.loginSuccessful = true
        //是否禁用
        this.disabledBtn = true;
        setTimeout(() => {
          this.disabledBtn = false;
        }, 1000);
        this.$refs[formName].validate(result => {
          if (result) {
            this.disabledBtn = true;
            //开启loading
            this.loading++;
            this.unClick = true;
            // 直接用手机表单登录接口
            this.$axios({
              url: '/auth/user/checked',
              method: 'post',
              params: {
                subject: 'SMS@' + (this.selectVal == '86' ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone),
                code: this.ruleForm.code
              },
              data: {
                subject: 'SMS@' + (this.selectVal == '86' ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone),
                code: this.ruleForm.code
              }
            }).then(data => {
              if (data.data.code == 0) {
                // this.registerSuccess = true
                this.Reiphone =  this.selectVal == '86' ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone,
                this.Retoken = data.data.data
                this.$refs[formName].resetFields();
                this.countdown = 60;
                clearInterval(this.timer);
                this.timer = null;
                this.codeDisabled = false;
                this.disabledBtn = false;
                this.loading = 0;
                this.wechatLogin = false;
                this.wechatAuth = false;
                this.unClick = false;
                this.active = 2
                // if (data.data.access_token) {
                //   this.setToken(data.data.access_token)
                // }
                //
                // this.$emit("loginDialog", false);
                // this.getUserInfo();
                // this.pageAjax();
                // this.$refs["ruleForm"].resetFields();
                // this.codeMsg = this.$t("logins.form.sendCodeText");
                // this.countdown = 60;
                // clearInterval(this.timer);
                // this.timer = null;
                // this.codeDisabled = false;
                // this.disabledBtn = false;
                // this.loading = 0;
                // this.wechatLogin = false;
                // this.wechatAuth = false;
                // this.unClick = false;
              }else {
                clearInterval(this.timer);
                this.countdown = 60;
                this.timer = null;
                this.codeDisabled = false;
                this.disabledBtn = false;
                this.loading = 0;
                this.$message({
                  message: data.data.msg
                });
              }
            })
              .catch(err => {
                clearInterval(this.timer);
                this.countdown = 60;
                this.timer = null;
                this.codeDisabled = false;
                this.disabledBtn = false;
                this.loading = 0;

              });
          }
        });
      },
      reload () {
        this.$axios.get('/auth/oauth/removeToken');
        let updatePhone = this.tool.getCookie("phone");
        this.tool.clearCookie("auth");
        this.$axios.post(
          '/auth/user/updateAutoLogin', {
            phone: updatePhone
          }
        ).then((data) => {
          if (data.data.code == 0) {
            if (data.data.data.access_token) {
              this.setToken(data.data.data.access_token)
            }
          }
        })
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
      // 登录弹层隐藏
      loginDialog (data) {
        this.dialogVisible = data;
        if (this.tool.getCookie("auth") != '') {
          this.$router.push({
            path: "/personalCenter/account"
          })
        }
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      backAccount () {
        if (!this.tool.getCookie("auth")) {
          this.dialogVisible = true;
        } else {
          clearInterval(this.fiveTime)
          this.$router.push({
            path: "/personalCenter/account"
          })
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .modidy_Auth {
    background: url("@{imgUrl}common/bg.png") no-repeat center bottom;
    background-size: 100%;
    padding: 144px 0 60px;
    box-sizing: border-box;
    min-height: calc(100vh - 84px);
    .main {
      width: 450px;
      height: 540px;
      background-color: #ffffff;
      box-shadow: 0px 6px 48px 0px rgba(110, 119, 123, 0.16);
      border-radius: 5px;
      margin: 0 auto;
      .step {
        border-radius: 5px 5px 0 0;
        height: 124px;
        background: @blue;
        padding: 40px 40px 30px;
        box-sizing: border-box;
      }
      .form {
        padding: 60px 55px 0;
        /deep/.el-input-group__prepend {
          border-right: 1px solid #DCDFE6;
          padding: 0 5px;
        }
        .code {
          position: relative;
          .code_btn {
            position: absolute;
            top: 0;
            right: 18px;
            background: #fff;
            color: #888;
            border: none;
            padding: 12px 0;
            &:hover {
              color: @blue;
            }
            &.is-disabled {
              color: #dce8fb;
            }
          }
        }
        .btn {
          width: 100%;
          height: 45px;
          border-radius: 5px;
          background: @blue;
          color: #fff;
          border: none;
          margin: 28px 0 0;
          font-size: 16px;
          &:hover {
            box-shadow: @boxShadow;
          }
        }
      }
      .success {
        padding: 0 55px;
        p {
          text-align: center;
          font-size: 20px;
          color: #595a5e;
          line-height: 254px;
          i {
            display: inline-flex;
            width: 36px;
            height: 36px;
            margin-right: 7px;
            vertical-align: middle;
            img {
              width: 100%;
            }
          }
        }
        .btn {
          display: block;
          width: 100%;
          height: 45px;
          line-height: 45px;
          text-align: center;
          border-radius: 5px;
          background: @blue;
          color: #fff;
          margin: 0 auto;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            box-shadow: @boxShadow;
          }
        }
      }
      .auth_code {
        dl {
          dt {
            width: 230px;
            height: 230px;
            margin: 50px auto 30px;
            border: 1px solid #c2c2c2;
            box-sizing: border-box;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          dd {
            p {
              font-size: 14px;
              color: #c2c2c2;
              text-align: center;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">
  .modidy_Auth {
    .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #dbdcdd;
      font-size: 14px;
      color: #888;
    }
    .el-form-item__error {
      padding-left: 15px;
    }
    .el-step__icon-inner {
      display: none;
    }
    .el-step__icon {
      width: 22px;
      height: 22px;
      background-color: @blue;
      border: solid 3px #2a87fc;
    }
    .el-step__head.is-finish {
      border-color: #fff;
      .el-step__icon.is-text {
        background-color: @blue;
        border: solid 3px #ffffff;
      }
    }
    .el-step__title.is-finish,
    .el-step__title.is-process,
    .el-step__title.is-wait {
      font-size: 14px;
      color: #fff;
      font-weight: normal;
    }
    .el-step__line {
      background-color: #2a87fc;
    }
    #qrcode {
      img {
        width: 100%;
      }
    }
  }
</style>
